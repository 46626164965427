import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/FooterInicio";
import DigitalAutomationCard from "../components/layout/DigitalAutomationCard";
import ImageDigital from "../assets/img/BannerCard.png";
import ButtomCall from "../components/common/ButtonCoach";

const Automation = () => {
  const sections = [
    {
      name: "Automatización de procesos",
      content: "Menos tareas manuales, más productividad.",
      imageSrc: ImageDigital,
    },
    {
      name: "Sistemas MES y ERP",
      content: "Datos precisos para decisiones rápidas.",
      imageSrc: ImageDigital,
    },
    {
      name: "Reducción de costos operativos",
      content: "Menos desperdicio y mejor gestión de recursos.",
      imageSrc: ImageDigital,
    },
    {
      name: " Control y monitoreo en tiempo real",
      content: "Desde cualquier lugar, en cualquier momento.",
      imageSrc: ImageDigital,
    },
  ];

  return (
    <div>
      <div className="pb-5">
        <Navbar />
      </div>
      <div className="pt-5">
        <div className="d-flex justify-content-center mt-4">
          <ButtomCall text={"Reserva tu asesoría"} />
        </div>
        <DigitalAutomationCard
          title="Optimiza tu producción con Automatización Inteligente"
          description="Reduce tiempos, minimiza errores y maximiza la eficiencia operativa"
          sections={sections}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Automation;
