import React from "react";

const DigitalAutomationCard = ({ title, description, sections }) => {

  return (
    <div className="container py-5">
      {/* Título */}
      <div className="text-center mb-4">
        <h1 className="fw-bold text-white">{title}</h1>
        <p className="fs-4 text-purple">{description}</p>
      </div>

      {/* Secciones */}
      <div className="row">
        {sections && sections.length > 0 ? (
          sections.map((section, index) => (
            <div key={index} className="col-md-6 mb-4">
              <div className="card shadow-sm h-100">
                <img
                  src={section.imageSrc}
                  className="card-img-top"
                  alt={section.name}
                  style={{ objectFit: "cover", height: "200px" }}
                />
                <div className="card-body">
                  <h4 className="fw-bold text-primary">{section.name}</h4>
                  <p>{section.content}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">No hay información disponible.</p>
        )}
      </div>
    </div>
  );
};

export default DigitalAutomationCard;
