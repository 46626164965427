import React from "react";
import Image from "../../assets/img/Vision.jpg";
import Image2 from "../../assets/img/Vision2.jpg";

const VisionBody = () => {
  return (
    <div className="container-innobody">
      <div id="banner-history" className="text-white container pt-5">

        <h2 className="text-center py-5">Visión</h2>
        <div className="d-grid d-md-flex container align-items-center items-center">
          <p className="text-center">
            Ser el aliado tecnológico clave en la transformación digital de la
            industria, impulsando la eficiencia y la toma de decisiones con
            soluciones innovadoras en ERP, MES y gemelo digital.
          </p>
          <img src={Image} alt="banner" className="w-50"/>
        </div>
        <hr className="hr-white-3 container" />
        <h2 className="text-center">Misión</h2>
        <div className="d-grid d-md-flex align-items-center items-center container">
          <p className="text-center">
            Digitalizar y automatizar procesos industriales mediante software
            avanzado, integrando datos en tiempo real para mejorar la
            productividad, reducir costos y optimizar la operación de cada
            empresa con tecnología escalable y personalizada.
          </p>
          <img src={Image2} alt="banner" className="w-50"/>
        </div>
      </div>
      <hr className="hr-white-3 container" />
    </div>
  );
};

export default VisionBody;
