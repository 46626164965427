import React from "react";

const useCaseBanner  = ({ title, description, modules }) => {
  return (
    <div id="use-cases" className="container pt-5">
      {/* Título y descripción */} 
      <div className="text-center mb-4 mt-5 pt-5 text-white">
        <h1 className="fw-bold">{title}</h1>
        <p className="fs-5 text-purple">{description}</p>
      </div>
      
      {/* Lista de módulos */}
      <div className="row g-4">
        {modules.map((module, index) => (
          <div key={index} className="col-md-6 col-lg-4 text-white">
            <div 
            className="card card-index border-0 shadow-sm p-3"
            style={{ width: "100%",
              minWidth: "100%",
              maxWidth: "100%",
              height: "100%",
              minHeight: "100%",
              maxHeight: "100%",
              objectFit: "cover", }}
            >
              {/* Imagen ilustrativa */}
              <img 
                src={module.imageSrc} 
                alt={module.name} 
                className="img-fluid rounded mb-3"
                style={{ objectFit: "cover", height: "200px", width: "100%" }}
              />
              
              {/* Contenido */}
              <h4 className="fw-bold">{module.name}</h4>
              <p>{module.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default useCaseBanner;
