import React, { forwardRef } from "react";
import { CaretRightFill } from "react-bootstrap-icons";

const Banner = forwardRef(
  ({ title, subtitle, description, imageSrc, imageAlt }, ref) => {
    return (
      <div id="banner" className="container mt-5" ref={ref}>
        <hr className="hr-white" />
        <div className="d-flex justify-content-center text-white ms-3 text-center">
          <h1 className="font-size-50">{title}</h1>
        </div>
        <div className="fw-bold text-white border-opacity-50 font-size-20 custom-tagline w-100 d-flex rounded-5">
          <p className="p-2 p-md-3">{subtitle}</p>
        </div>
        <div className="d-grid d-md-flex">
          <ul className="font-size-20 text-white text-start list-unstyled">
            {description.map((item, index) => (
              <li key={index}><CaretRightFill style={{ color: "#6B24BD"}}/> {item}</li>
            ))}
          </ul>
          <img
            src={imageSrc}
            className="d-flex w-md-50 w-100 ms-md-5" style={{ maxWidth: "600px", maxHeight: "400px" }}
            alt={imageAlt}
          />
        </div>
      </div>
    );
  }
);

export default Banner;
