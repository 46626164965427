import { useState, useEffect } from "react";
import { ArrowRightCircle } from "react-bootstrap-icons";
import Image1 from "../../assets/img/Services.png";
import Image2 from "../../assets/img/Service2.jpg";
import Image3 from "../../assets/img/Service3.png";

const imageList = [Image1, Image2, Image3];
const textList = [
  "Sistema centralizado que gestiona y optimiza procesos clave como producción, finanzas y ventas, integrando todas las áreas de la empresa en una sola plataforma.",
  "Controla y monitorea la producción en tiempo real, mejorando eficiencia, calidad y trazabilidad en la fábrica. Conecta máquinas, operadores y datos para optimizar la operación.",
  " Réplica digital de procesos, productos o plantas industriales que permite simular, analizar y optimizar el rendimiento antes de aplicar cambios en el mundo real.",
];
const textList2 = ["ERP (Enterprise Resource Planning)", "MES (Manufacturing Execution Systems)", "Digital Twin"];
const textList3 = [
  "Sistema de planificación de recursos empresariales",
  "Sistema de Manufactura",
  "Gemelo digital",
];

const rutes = [
  <h6>Conoce nuestro ERP</h6>,
  <h6>
    Conoce nuestro M.E.S
  </h6>,
  <h6>Conoce nuestro Digital Twin</h6>,
];
const transitionDuration = 7000;

export const BannerServices = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextImageIndex((currentImageIndex + 1) % imageList.length);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageList.length);
    }, transitionDuration);

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex]);

  const handleButtonClick = (index) => {
    if (index === 0) {
      const bannerLandingElement = document.getElementById("banner-landing");

      if (bannerLandingElement) {
        bannerLandingElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (index === 1) {
      const bannerLandingElement = document.getElementById("banner-sistem");

      if (bannerLandingElement) {
        bannerLandingElement.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (index === 2) {
      const bannerLandingElement = document.getElementById("banner-marketing");

      if (bannerLandingElement) {
        bannerLandingElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <section className="banner-service position-relative px-lg-5 px-md-3 px-0 py-5 z-2">
      <div className="container-banner-service mt-5">
        <hr className="hr-white-2" />
        <span className="tagline-service z-2 d-unset position-relative ms-md-5 ms-2 text-white font-weight-700 bg-dark-gradient p-2 font-size-20 ">{textList2[currentImageIndex]}</span>
        <h1 className="text-white position-relative font-size-40 font-weight-700 mb-3 ms-2 ms-md-5 d-block z-2 mt-5 mt-md-3">
          {textList3[currentImageIndex]}
        </h1>
        <p className="ms-md-5 me-md-5 ms-2 text-white z-2 position-relative">{textList[currentImageIndex]}</p>
        <div className="d-flex justify-content-center justify-content-md-start ms-md-5">
          <button
            className="btn-connect position-relative z-2 bg-transparent p-3 mt-2 d-flex align-items-md-center align-items-end"
            onClick={() => handleButtonClick(currentImageIndex)}
          >
            {rutes[currentImageIndex]} <ArrowRightCircle size={25} className="ms-2"/>
          </button>
        </div>
      </div>
      <div className="top-0 start-0 position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
        <div className="position-relative z-0 w-100 h-100 overflow-hidden">
          {imageList.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Metacode ${index}`}
              className="transition-opacity position-absolute top-0 start-0 w-100 h-100 img-fluid z-2"
              style={{
                objectFit: "cover",
                opacity:
                  index === currentImageIndex || index === nextImageIndex
                    ? 1
                    : 0,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BannerServices;
