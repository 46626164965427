import React, { useRef } from "react";
import "../../styles/banner/Banner.css";
import "../../styles/Slider.css";
import {
  BuildingsFill,
  TelephoneInboundFill,
  Arrow90degDown,
} from "react-bootstrap-icons";
import Image from "../../assets/img/IndicatorDLE.png";
import Image2 from "../../assets/img/IndicatorFTQ.png";
import Image3 from "../../assets/img/IndicatorOEE.png";
import Image4 from "../../assets/img/Slider.png";
import Image5 from "../../assets/img/Slider2.png";
import Image7 from "../../assets/img/Slider4.png";
import Image8 from "../../assets/img/Slider5.png";
import Image9 from "../../assets/img/Slider6.png";
import Image10 from "../../assets/img/IndicatorMTBF.png";
import Image11 from "../../assets/img/IndicatorDowntime.png";
import Image12 from "../../assets/img/IndicatorScrap.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";
import CardIndex from "../Cards/CardsIndex"; // Importa el componente

const BannerHome = () => {
  const cardIndexRef = useRef(null); // Referencia al componente CardIndex

  const handleScroll = () => {
    if (cardIndexRef.current) {
      cardIndexRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div className="banner-container flex-column">
        <div className="banner-overlay"></div>
        <div className="content">
          <div className="banner-label border border-white mx-auto border-opacity-25 mb-5">
            Software industrial 4.0 <BuildingsFill />
          </div>
          <Fade delay={200} duration={900} triggerOnce fraction={0.5}>
            <h1 className="banner-title">
              Automatizamos y digitalizamos <br /> procesos{" "}
              <span className="highlight">industriales</span>
            </h1>
          </Fade>
          <Slide direction="left" delay={200} duration={900}>
            <p className="banner-description">
              Diseñamos soluciones personalizadas que optimizan recursos,
              reducen costos y conectan datos clave para mejorar la toma de
              decisiones en tu industria.
            </p>
          </Slide>

          <div className="banner-buttons">
            <button className="btn btn-outline-light me-2" onClick={handleScroll}>
              <Arrow90degDown className="me-2" />
              Conoce más
            </button>
            <a href="https://calendly.com/metacodearg/30min">
              <button className="btn btn-connect">
                Agenda una reunión
                <TelephoneInboundFill className="ms-2 mb-1" />
              </button>
            </a>
          </div>

          <Fade delay={1000} duration={1200}>
            <h3 className="mt-3">El poder de la industria 4.0 en tus manos</h3>
          </Fade>
          <div className="bg-gradient-banner">
            <Slider dots={true} infinite={true} slidesToShow={3} slidesToScroll={2} autoplay={true} speed={9000} autoplaySpeed={0}>
              {[Image, Image2, Image3, Image4, Image5, Image7, Image8, Image9, Image10, Image11, Image12].map((img, index) => (
                <div key={index} className="container-slider">
                  <img src={img} alt={`Imagen ${index}`} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <hr className="hr-white" />

      {/* Sección CardIndex con la referencia */}
      <div ref={cardIndexRef}>
        <CardIndex />
      </div>
    </>
  );
};

export default BannerHome;
