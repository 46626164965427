import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/FooterInicio";
import DigitalAutomationCard from "../components/layout/DigitalAutomationCard";
import ImageDigital from "../assets/img/BannerCard.png";
import ButtomCall from "../components/common/ButtonCoach";

const Digital = () => {
  const sections = [
    {
      name: "Digitalización de procesos",
      content: "Centralizamos toda tu operación en un solo sistema.",
      imageSrc: ImageDigital,
    },
    {
      name: "Conectividad total",
      content: " Integramos datos desde ERP, MES y Gemelo digital.",
      imageSrc: ImageDigital,
    },
    {
      name: "Trazabilidad y análisis",
      content:
        "Datos históricos y en tiempo real para tomar mejores decisiones.",
      imageSrc: ImageDigital,
    },
    {
      name: "Implementación de gemelo digital",
      content: "Simulación y optimización antes de ejecutar cambios.",
      imageSrc: ImageDigital,
    },
  ];

  console.log(sections);
  return (
    <div>
      <div className="pb-5">
        <Navbar />
      </div>

      <div className="pt-5">
        <div className="d-flex justify-content-center mt-4">
          <ButtomCall text={"Reserva tu asesoría"} />
        </div>
        <DigitalAutomationCard
          title="Transformamos tus datos en decisiones inteligentes"
          description="Del papel y Excel a sistemas avanzados para optimizar tu industria"
          sections={sections}
        />
      </div>
      <Footer />
    </div>
  );
};

export default Digital;
