import React from 'react'
import NavbarManagement from '../components/Navbar/Navbar'
import BannerHome from '../components/Banner/BannerHome'
// import CardIndex from '../components/Cards/CardsIndex'
// import BannerVideo from '../components/Banner/BannerVideo'
import Footer from '../components/Footer/FooterInicio'
// import ButtonCoach from '../components/Buttons/ButtonCoach'
import "../styles/Background.css"
import "../styles/fonts/_fontsize.css"
import "../styles/sizes/_widths.css"
import "../styles/animations.css"
import ProfileCard from '../components/About/ProfileCard'


const Home = () => {
  return (
    <div>
    <NavbarManagement/>
    <BannerHome/>
    {/* <CardIndex/> */}
    <br/><hr className='hr-white'/>
    <ProfileCard/>
    {/* <BannerPartner/>
    <br/>
    <ButtonCoach/>
    <br/>
    <hr className='hr-white-3'/>
    <SliderClients/>
    <br/><br/><hr className='hr-white-3'/>*/}
    <Footer/> 
    </div>
  )
}

export default Home