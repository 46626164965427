import React from "react";
import Navbar from "../components/Navbar/Navbar";
import VisionBody from "../components/VisionMision/VisionBody";
import Footer from "../components/Footer/FooterInicio";

const Vision = () => {
  return (
    <>
      <Navbar />
      <div className="pt-5">
        <VisionBody />
      </div>
      <Footer />
    </>
  );
};

export default Vision;
