import UseCaseBanner from "../components/UseCases/UseCaseBanner";
import UseCase from "../assets/img/UseCase.png"
import UseCase2 from "../assets/img/UseCase2.png"
import UseCase3 from "../assets/img/UseCase3.png"
import UseCase4 from "../assets/img/UseCase4.png"
import UseCase5 from "../assets/img/UseCase5.png"
import UseCase6 from "../assets/img/UseCase6.png"
import UseCase7 from "../assets/img/UseCase7.png"
import UseCase8 from "../assets/img/UseCase8.png"
import UseCase9 from "../assets/img/UseCase9.png"
import UseCase10 from "../assets/img/UseCase10.png"
import UseCase11 from "../assets/img/UseCase11.png"
import UseCase12 from "../assets/img/UseCase12.png"

import NavbarManagement from "../components/Navbar/Navbar";
import "../styles/Text.css"

const modules = [
  {
    name: "Gestión de Horas Extra",
    description: "Gestiona y aprueba solicitudes de horas extra de forma rápida y sencilla.",
    imageSrc: UseCase,
  },
  {
    name: "Rendiciones de Gastos",
    description: "Administra reembolsos y gastos de manera eficiente.",
    imageSrc: UseCase2,
  },
  {
    name: "Gestion de logística",
    description: "Gestion de inventarios y almacenes avanzada.",
    imageSrc: UseCase11,
  },
  {
    name: "Calidad",
    description: "La herramienta definitiva para tus auditorías, controles de calidad y gestión de reclamos",
    imageSrc: UseCase7,
  },
  {
    name: "Operation Meeting",
    description: "Tu mejor herramienta para el seguimiento de reuniones de operaciones.",
    imageSrc: UseCase3,
  },
  {
    name: "Ausentismo",
    description: "Controla y registra el ausentismo de tus empleados de manera eficiente.",
    imageSrc: UseCase4,
  },
  {
    name: "Reporting",
    description: "Obtén informes claros y datos clave al instante.",
    imageSrc: UseCase5,
  },
  {
    name: "Pases de Salida",
    description: "Autoriza y gestiona permisos de salida con tan solo un clic.",
    imageSrc: UseCase6,
  },
  {
    name: "Administración de personal",
    description: "Gestiona información y movimientos de empleados",
    imageSrc: UseCase8,
  },
  {
    name: "Solicitud de cheques",
    description: "Solicita y aprueba cheques de manera rápida y ordenada.",
    imageSrc: UseCase9,
  },
  {
    name: "Reportes de finanzas",
    description: "Accede a informes financieros de tu empresa generados en tiempo real",
    imageSrc: UseCase10,
  },
  {
    name: "Acciones de gerencia",
    description: "Tareas administrativas y de control para gerentes.",
    imageSrc: UseCase12,
  },
];

const useCase = () => {
    return (
      <div>
        <NavbarManagement/>
          <UseCaseBanner
          title="Casos de Uso - METACODE ERP" 
          description="Descubre cómo cada módulo optimiza la gestión empresarial." 
          modules={modules} />
      </div>
    )
  }
export default useCase
