import React from "react";
import Indicators from "../components/Industry/Indicators";
import Image from "../assets/img/Slider.png";
import OEE from "../assets/img/IndicatorOEE.png";
import FTQ from "../assets/img/IndicatorFTQ.png";
import DLE from "../assets/img/IndicatorDLE.png";
import MTBF from "../assets/img/IndicatorMTBF.png";
import Downtime from "../assets/img/IndicatorDowntime.png";
import ScrapRate from "../assets/img/IndicatorScrap.png";
import NavbarManagement from "../components/Navbar/Navbar";

const indicators = [
  {
    name: "OEE (Overall Equipment Effectiveness) – Eficiencia Global de los Equipos",
    whatItMeasures:
      "La eficiencia total de un equipo considerando disponibilidad, rendimiento y calidad.",
    problemSolved:
      "Permite visualizar tiempos muertos, pérdidas en producción y desperdicios.",
    example:
      "Una línea de producción con bajo OEE podría estar sufriendo paradas frecuentes, velocidad reducida o alto porcentaje de productos defectuosos.",
    imageSrc: OEE,
  },
  {
    name: "FTQ (First Time Quality) – Calidad en el Primer Intento",
    whatItMeasures:
      "El porcentaje de productos fabricados correctamente sin necesidad de retrabajo o reprocesos.",
    problemSolved:
      "Muestra el impacto de defectos en costos de producción y tiempos de entrega.",
    example:
      "Si una planta tiene un FTQ bajo, significa que muchos productos deben corregirse o descartarse, lo que aumenta costos y retrasa entregas.",
    imageSrc: FTQ,
  },
  {
    name: "DLE (Direct Labor Efficiency) – Eficiencia de la Mano de Obra Directa",
    whatItMeasures:
      "El porcentaje de tiempo productivo de los operarios en comparación con el tiempo total disponible.",
    problemSolved:
      "Ayuda a identificar pérdidas de productividad, tiempos muertos y asignaciones ineficientes de recursos.",
    example:
      "Si los operarios están ocupados solo el 60% del tiempo disponible, hay una oportunidad de optimización en los procesos o la planificación del trabajo.",
    imageSrc: DLE,
  },
  {
    name: "MTBF (Mean Time Between Failures) – Tiempo Medio Entre Fallas",
    whatItMeasures: "El tiempo promedio que un equipo funciona sin fallar.",
    problemSolved:
      "Permite visualizar patrones de fallas y mejorar planes de mantenimiento preventivo.",
    example:
      "Si una máquina tiene un MTBF bajo, significa que falla con demasiada frecuencia y requiere mejoras en mantenimiento o en la calidad de sus componentes.",
    imageSrc: MTBF,
  },
  {
    name: "MTTR (Mean Time to Repair) – Tiempo Medio de Reparación",
    whatItMeasures: "El tiempo promedio que se tarda en reparar un equipo después de una falla.",
    problemSolved:
      "Visualiza el tiempo de inactividad al optimizar procesos de mantenimiento y disponibilidad de repuestos.",
    example:
      "Si una máquina tiene un MTTR alto, significa que cada vez que falla, toma demasiado tiempo restaurarla, lo que impacta la producción. Esto puede indicar problemas en la gestión de repuestos, capacitación del personal o procesos de mantenimiento deficientes.",
    imageSrc: MTBF,
  },
  {
    name: "Downtime Monitoring – Monitoreo de Tiempos de Inactividad",
    whatItMeasures:
      "La cantidad de tiempo en que una máquina o línea de producción está detenida.",
    problemSolved:
      "Identifica las causas de paradas para tomar decisiones sobre su reducción y mejorar disponibilidad.",
    example:
      "Un sistema MES puede detectar que el 30% del tiempo de inactividad se debe a, por ejemplo, cambios de herramientas innecesariamente largos o paradas no programadas por mantenimiento, lo que permite tomar medidas correctivas.",
    imageSrc: Downtime,
  },
  {
    name: "Scrap % – Porcentaje de Desperdicio",
    whatItMeasures:
      "La cantidad de material desperdiciado en el proceso de producción en relación con la producción total.",
    problemSolved:
      "Permite analizar el impacto del desperdicio en costos y eficiencia de recursos.",
    example:
      "Si una fábrica tiene un Scrap % alto, puede indicar problemas en la calibración de las máquinas o en la calidad de las materias primas utilizadas.",
    imageSrc: ScrapRate,
  },
];

const HomePage = () => {
  return (
    <div>
      <NavbarManagement />

      <Indicators
        title="Indicadores clave en la Industria 4.0"
        subtitle="Optimiza procesos, reduce costos y mejora la productividad con datos en tiempo real."
        indicators={indicators}
        imageSrc={Image}
        imageAlt="Indicadores industriales"
      />
    </div>
  );
};

export default HomePage;
