import React, { forwardRef } from "react";
import { Bounce } from "react-awesome-reveal";

const Indicators = forwardRef(({ title, subtitle, indicators }, ref) => {
  return (
    <div id="banner" className="container w-100 w-md-50 pt-5" ref={ref}>
      <hr className="hr-white" />
      <div className="text-center text-white mt-5">
        <h1 className="fw-bold p-2">{title}</h1>
        <p className="fs-4">{subtitle}</p>
      </div>
      <div className="d-grid gap-4">
        {indicators.map((indicator, index) => (
          <div
            key={index}
            className="d-flex flex-column flex-md-row align-items-center border-none rounded bg-dark-gradient p-3"
          >
            <Bounce trasition={1000} duration={5000}>
              <img
                src={indicator.imageSrc}
                className="img-fluid w-100 w-md-25 rounded mb-md-0 me-md-4"
                style={{ width: "200px",
                  minWidth: "200px",
                  maxWidth: "200px",
                  height: "200px",
                  minHeight: "200px",
                  maxHeight: "200px",
                  objectFit: "cover", }}
                alt={indicator.name}
              />
            </Bounce>
            <div className="text-white">
              <h4 className="fw-bold text-purple text-center text-md-start">
                {indicator.name}
              </h4>
              <p>
                🟣 <strong>Qué mide:</strong> {indicator.whatItMeasures}
              </p>
              <p>
                🟣 <strong>¿En que te beneficia tenerlo?</strong>{" "}
                {indicator.problemSolved}
              </p>
              <p>
                🟣 <strong>Ejemplo de uso:</strong> {indicator.example}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Indicators;
