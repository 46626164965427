import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Footer.css";
import navIcon1 from "../../assets/img/nav-icon1.svg";
import Instagram from "../../assets/img/Instagram.webp";
import { TelephoneInboundFill } from "react-bootstrap-icons";
import Icon from "../../assets/img/icon.png";

export const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer className="container">
      <hr className="hr-white" />
      <section className="footer-top-wrapper">
        <section className="footer-top d-grid items-center">
          <a href="https://calendly.com/metacodearg/30min" className="ms-3">
            <button className="btn mb-2 justify-content-center me-3 btn-connect rounded">
              Agenda una reunión
              <TelephoneInboundFill className="ms-2 mb-1" />
            </button>
          </a>
          <span className="social-icon">
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7090171525525299200?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7090171525525299200%29">
              <img src={navIcon1} alt="Social Icon" />
            </a>
            <a href="https://www.instagram.com/metacodeargentina/">
              <img src={Instagram} alt="Social Icon" />
            </a>
          </span>
        </section>

        <section>
          <menu className="menu-footer">
            <h2 className="mb-3">Metacode Learning</h2>
            <li>
              <Link to="/digitalization">
                <a>Digitalización</a>
              </Link>
            </li>
            <li>
              <Link to="/Automation">
                <a>Automatización</a>
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/indicators">Indicadores</Link>
            </li>
          </menu>
        </section>

        <section>
          <menu className="menu-footer">
            <h2 className="mb-3">Servicios</h2>
            <li>
              <Link to="/services">ERP</Link>
            </li>
            <li>
              <Link to="/services">DT</Link>
            </li>
            <li>
              <Link to="/services">MES</Link>
            </li>
          </menu>
        </section>
        <section>
          <menu className="menu-footer">
            <h2 className="mb-3">Empresa</h2>
            {/* <li>
              <Link to="/projects">Noticias</Link>
            </li> */}
            <li>
              <Link to="mailto:nahuel.flores@metacode.com.ar">Contacto</Link>
            </li>
          </menu>
        </section>

        {/* <section>
          <menu className="menu-footer">
            <h2 className="mb-3">Productos</h2>
            <li>
              <Link to="/plans">Planes</Link>
            </li>
            <li>
              <Link to="#connect">Barber Map</Link>
            </li>
            <li>
              <Link to="#connect">CapturePro Events</Link>
            </li>
          </menu>
        </section>
        <section>
          <menu className="menu-footer">
            <h2 className="mb-3">Empresa</h2>
            <li>
              <Link
                to="https://wa.me/3512035657?text=Quiero%20asesoramiento
"
              >
                Contacto
              </Link>
            </li>
            <li>
              <Link to="#connect">Noticias</Link>
            </li>
          </menu>
        </section> */}
      </section>
      <section className="footer-bottom">
        <small>
          Todo el contenido &copy; 2022 - {year}
          <img src={Icon} alt="footer-bottom" /> Metacode | Todos los derechos
          reservados
        </small>
      </section>
    </footer>
  );
};

export default Footer;
