import React, {useState, useEffect} from "react";
import ProfileImage from "../../assets/img/3.png";
import MagnaIcon from "../../assets/img/MagnaIcon.webp";
import LinkedInIcon from "../../assets/img/nav-icon1.svg";

const ProfileCard = () => {

  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    setTimeout(() => setRerender(true), 100);
  }, []);
  return (
    <div className="p-5 mx-auto text-white w-100 w-800">
      <div className="flex-column flex-md-row d-flex align-items-center gap-3">
        <div className="items-center d-grid">
          <div className="position-relative height-150 width-150">
            <img
              src={ProfileImage}
              className="fluid w-100 h-100 rounded-circle border border-dark"
              alt="Imagen redonda"
            />
            <a
              href="https://www.linkedin.com/in/nahuelgflores/"
              target="_blank"
              rel="noopener noreferrer"
              className="linkedin-badge"
            >
              <img src={LinkedInIcon} alt="LinkedIn" />
            </a>
            {rerender && <div className="circle-spin"></div>}
          </div>
          <div className="d-grid items-center">
            <h2>Nahuel Flores</h2>
            <h6>Fundador de Metacode</h6>
          </div>
        </div>
        <div className="text-content">
          <h2 className="title font-size-25 text-white">
            Analista en sistemas
          </h2>
          <div className="d-flex justify-content-center justify-content-md-start">
            <h3 className="font-size-15">
              <a
                href="https://www.magna.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none text-white"
              >
                <img
                  src={MagnaIcon}
                  className="me-2"
                  alt="Magna International"
                  style={{ width: "10%" }}
                />
                MAGNA INTERNATIONAL
              </a>
            </h3>
          </div>

          <p className="font-size-15">
            A lo largo de mi carrera como Software Engineer, he desarrollado un
            amplio conocimiento en el sector industrial, diseñando e
            implementando tecnologías avanzadas para automatización,
            trazabilidad y gestión de producción. Fundé Metacode, una startup
            que impulsa la transición de las empresas hacia la Industria 4.0
            mediante soluciones integradas de software ERP, MES y DIGITAL TWIN.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
