import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../styles/Navbar.css";
import "../../styles/sizes/_heights.css";
import "@fortawesome/fontawesome-free/css/all.css";
import LogoMetacode from "../../assets/img/LogoMetacode.webp";
import navIcon1 from "../../assets/img/nav-icon1.svg";
import Instagram from "../../assets/img/Instagram.webp";
import Image from "../../assets/img/Carousel1.webp";
import Image3 from "../../assets/img/Carousel3.webp";
import Image4 from "../../assets/img/img_servicio.webp";
import { TelephoneInboundFill } from "react-bootstrap-icons";
class NavbarManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      windowWidth: window.innerWidth,
      scrolled: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleWindowSizeChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleScroll() {
    const { scrolled } = this.state;
    const isScrolled = window.scrollY > 0;

    if (isScrolled !== scrolled) {
      this.setState({ scrolled: isScrolled });
    }
  }

  handleClick = () => {
    this.setState((prevState) => ({ clicked: !prevState.clicked }));
  };

  render() {
    const { clicked, windowWidth, scrolled } = this.state;
    const isMobile = windowWidth <= 768;

    return (
      <nav className={`nav-items ${scrolled ? "scrolled" : ""}`}>
        <div className="LogoNavbar">
          <Link to="/">
            <img src={LogoMetacode} alt="BARBER-MAP"></img>
          </Link>
        </div>
        {/*DESPLAZAMIENTO DE NAVBAR MOBILE*/}
        <div className="menu-icons" onClick={this.handleClick}>
          <i className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>

        <ul className={clicked ? "nav-menu active" : "nav-menu"}>
          {isMobile ? (
            <>
              <li className="item-navbar">
                <Link className="nav-links-mobile" to="/">
                  <i className="fas fa-home icon-navbar"></i> Inicio
                </Link>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fas fa-cube icon-navbar"></i> Servicios
                </Link>
                <ul className="submenu-drop">
                  <li className="item-submenu2">
                    <Link to="/services">
                      <i class="fa-solid fa-list icon-navbar"></i>
                      ERP
                    </Link>
                  </li>
                  <li className="item-submenu2">
                    <Link to="/services">
                      <i class="fa-solid fa-list icon-navbar"></i>
                      MES
                    </Link>
                  </li>
                  <li className="item-submenu2">
                    <Link to="/services">
                      <i className="fas fa-building-wheat icon-navbar" />
                      Gemelo digital
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fa-cogs icon-navbar"></i> Industrias
                </Link>
                <ul className="submenu-drop height-300">
                  <li className="item-submenu">
                    <Link to="/indicators">
                      <i class="fa-solid fa-network-wired icon-submenu"></i>
                      (KPIs) - Indicadores clave
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/use-cases">
                      <i class="fa-solid fa-arrow-up-right-dots icon-submenu"></i>
                      Casos de uso
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/digitalization">
                      <i class="fa-regular fa-lightbulb icon-submenu"></i>
                      Digitalización
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="/automation">
                      <i class="fa-solid fa-industry icon-submenu"></i>
                      Automatización
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links-mobile">
                  <i className="fas fa-building icon-navbar"></i> Empresa
                </Link>
                <ul className="submenu-drop height-300">
                  <li className="item-submenu">
                    <Link to="/vision">
                      <i class="fa-solid fa-earth-americas icon-submenu"></i>
                      Mision y Vision
                    </Link>
                  </li>
                  <li className="item-submenu">
                    <Link to="mailto:nahuel.flores@metacode.com.ar">
                      Contacto
                    </Link>
                  </li>
                  {/* <li className="item-submenu">
                    <Link>
                      <i class="fa-solid fa-bullhorn icon-submenu"></i>Noticias
                      y actualizaciones
                    </Link>
                  </li> */}
                </ul>
              </li>
            </>
          ) : (
            <div className="d-flex container align-items-baseline justify-content-center">
              {/*VISTA DE ESCRITORIO*/}
              <li className="item-navbar">
                <Link className="nav-links" to="/">
                  Inicio
                </Link>
              </li>
              <li className="item-navbar">
                <Link className="nav-links">Servicios</Link>
                <ul id="submenu" className="submenu-drop-desk">
                  <div className="submenu-left">
                    <img src={Image4} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link to="/services">
                        (ERP) - Sistema de planificación de recursos
                        empresariales
                      </Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/services">(DT) - Gemelo digital </Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/services">(MES) - Sistema de Manufactura</Link>
                    </li>
                  </div>
                </ul>
              </li>
              <li className="item-navbar">
                <Link className="nav-links">Industrias</Link>
                <ul id="submenu" className="submenu-drop-desk">
                  <div className="submenu-left">
                    <img src={Image} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link to="/indicators">(KPIs) - Indicadores clave</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/use-cases">Casos de uso</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/digitalization">Digitalización</Link>
                    </li>
                    <br />
                    <li>
                      <Link to="/automation">Automatización</Link>
                    </li>
                    <br />
                  </div>
                </ul>
              </li>

              <li className="item-navbar">
                <Link className="nav-links">Empresa</Link>
                <ul id="submenu" className="submenu-drop-desk">
                  <div className="submenu-left">
                    <img src={Image3} alt="Metacode" className="col-6" />
                  </div>
                  <div className="submenu-items col-6">
                    <li>
                      <Link to="/vision">Misión y Visión</Link>
                    </li>
                    <br />
                    <li>
                      <a href="mailto:nahuel.flores@metacode.com.ar">
                        Contacto
                      </a>
                    </li>
                    <br />
                    {/* <li>
                      <Link>Noticias y Actualizaciones</Link>
                    </li> */}
                  </div>
                </ul>
              </li>
              <a href="https://calendly.com/metacodearg/30min" className="ms-3">
                <button className="btn btn-connect p-3">
                  <span>Agenda una reunión</span>{" "}
                  <TelephoneInboundFill className="ms-2 mb-1" />
                </button>
              </a>
            </div>
          )}
          <hr className="divider" />
          <span className="ms-3 d-block d-md-none">
            <div className="social-icon mb-3">
              <a href="https://www.linkedin.com/feed/update/urn:li:activity:7090171525525299200?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7090171525525299200%29">
                <img src={navIcon1} alt="LinkedIn Icon" />
              </a>
              <a
                href="https://www.instagram.com/metacodeargentina/"
                className="ms-3"
              >
                <img src={Instagram} alt="WhatsApp Icon" />
              </a>
            </div>
            <Link to="https://calendly.com/metacodearg/30min">
              <button className="btn btn-connect p-3">
                <span>Agenda una reunión</span>{" "}
                <TelephoneInboundFill className="ms-2 mb-1" />
              </button>
            </Link>
          </span>
        </ul>
      </nav>
    );
  }
}

export default NavbarManagement;
