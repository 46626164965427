import React from "react";
import { ArrowRightCircle } from "react-bootstrap-icons";

const ButtonCoach = ({ text }) => {
  return (
    <div>
      <a
        href="https://calendly.com/metacodearg/30min"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <button className="  btn btn-connect p-3">
            {text} <ArrowRightCircle size={25} className="icon-service" />
          </button>
        </div>
      </a>
    </div>
  );
};

export default ButtonCoach;
