import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/FooterInicio";
import BannerServices from "../components/Services/BannerServices";
import Banner from "../components/layout/Banner";
import Image from "../assets/img/ERP.webp";
import Image2 from "../assets/img/mes.webp";
import Image3 from "../assets/img/DT.png";

const Services = () => {
  return (
    <div>
      <Navbar />
      <BannerServices />
      <Banner
        title="Sistema de planificación de recursos empresariales"
        subtitle="¿En que te beneficia integrar nuestro sistema ERP?"
        description={[
          "Aumenta la eficiencia operativa, automatizando procesos y centralizando la información",
          "Mejora la toma de decisiones con datos en tiempo real",
          "Facilita el cumplimiento normativo con controles de calidad",
        ]}
        imageSrc={Image}
        imageAlt="Descripción de la imagen"
      />
      <Banner
        title="Sistema de Manufactura"
        subtitle="¿En que te beneficia integrar nuestro sistema MES?"
        description={[
          "Optimiza la calidad del producto registrando cada etapa del proceso y detectando fallas antes de que afecten la producción.",
          "Reduce tiempos muertos y mejora la productividad, detectando cuellos de botella permitiendote actuar rápido.",
          "Conexión total con el ERP, sincronizando datos de planta con planificación y logística.",
        ]}
        imageSrc={Image2}
        imageAlt="Descripción de la imagen"
      />
      
      <Banner
        title="Gemelo digital"
        subtitle="¿En que te beneficia integrar nuestro Gemelo digital?"
        description={[
          "Reduce costos de prueba y error permitiendote evaluar cambios en procesos sin impactar la producción real",
          "Optimiza el uso de recursos y energía detectando ineficiencias y propone mejoras en tiempo real.",
          "Predice problemas antes de que ocurran simulando escenarios para anticipar fallas y optimizar decisiones",
          "Acelera la innovación a travéz de pruebas virtuales de nuevos productos y procesos sin frenar la operación."
        ]}
        imageSrc={Image3}
        imageAlt="Descripción de la imagen"
      />
      <Footer />
    </div>
  );
};

export default Services;
