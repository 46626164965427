import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/Cards.css";
import "../../styles/Buttons.css";
import { Link } from "react-router-dom";

const CardIndex = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {/* Tarjeta grande */}
        <hr className="hr-white" />

        <div className="col-lg-6 col-6">
          <div className="card border border-0 mb-4 card-index height-250">
            <div className="text-white ms-3">
              <h5 className="font-size-40">Mas allá del código</h5>
              <p className="font-size-20">
                ERP, MES y Gemelo Digital para la Industria 4.0
              </p>
              <Link to="/services" className="text-decoration-none">
                <button className="btn-card">Ver Servicios</button>
              </Link>
            </div>
          </div>
        </div>
        {/* Cards pequeñas en layout 3x3 en mobile */}

        <div className="col-lg-3 col-md-6 col-6">
          <div className="card card-index border border-0 height-250">
            <div className="text-white ms-3 me-3">
              <h5 className="font-size-40">KPIs</h5>
              <p className="font-size-20">
                Todos los indicadores que necesitas a tu disposición
              </p>
              <Link to="/indicators" className="text-decoration-none">
                <button className="btn-card w-75 mt-2">Ver Indicadores</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-6">
          <div className="card card-index border border-0 height-250 mb-4">
            <div className="text-white ms-3 me-3">
              <h5 className="font-size-40">Casos de uso</h5>
              <p className="font-size-20">Como podemos ayudar a tu industria</p>
              <Link to="/use-cases" className="text-decoration-none">
                <button className="btn-card w-75 mt-2">Descubre Más</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-6">
          <div className="card card-index border border-0 height-250">
            <div className="text-white ms-3 me-3">
              <h5 className="font-size-40">Innovación</h5>
              <p className="font-size-20">
                Donde se visualizan los datos, la industria avanza
              </p>
              <Link to="/digitalization" className="text-decoration-none">
                <button className="btn-card w-75 mt-2">Más información</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-6">
          <div className="card card-index border border-0 height-250">
            <div className="text-white ms-3 me-3">
              <h5 className="font-size-40">Visión y Misión</h5>
              <p className="font-size-20">Transformando el presente</p>
              <Link to="/vision" className="text-decoration-none">
                <button className="btn-card w-75 mt-2">Ver más</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-6">
          <div className="card card-index border border-0 height-250 mb-4">
            <div className="text-white ms-3">
              <h5 className="text-white font-size-40">Automatización</h5>
              <p className="font-size-20 text-white">
                Reduce tiempos, minimiza errores y maximiza la eficiencia
                operativa
              </p>
              <Link to="/automation" className="text-decoration-none">
                <button className="btn-card mt-2">Ver más</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardIndex;
