import './App.css'
import { Route, Routes, HashRouter as BrowserRouter } from "react-router-dom";
// Rutas generales
import Home from './pages/Home';
import Services from "./pages/Services"
import Industry from "./pages/Industry"
import UseCase from "./pages/UseCases"
import ScrollToTop from './components/common/ScrollToTop';
import Digital from './pages/Digital';
import Automation from './pages/Automation';
import Vision from './pages/Vision';

const App = () => {

  return (
    <>
      <div className='App'>
        <BrowserRouter>
        <ScrollToTop/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/services' element={<Services/>}/>
            <Route path='/indicators' element={<Industry/>}/>
            <Route path='/use-cases' element={<UseCase/>}/>
            <Route path='/digitalization' element={<Digital/>}/>
            <Route path="/automation" element={<Automation/>}/>
            <Route path='/vision' element={<Vision/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App